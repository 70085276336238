@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.PopupVerdict {
	position: absolute;
	padding: 0.942857em 1.17em 0.942857em 1.21em;
	width: 38.57142em;
	height: 18.5714em;
	top: 4.2142em;
	left: 4.7857em;
	background-color: rgb(255, 255, 255);
	border-radius: 1.75em;
	z-index: 20;
	pointer-events: none;
	visibility: hidden;
	@include opacity(0);
	@include doubleTransition('opacity', 0.33s, 'ease-in-out', 'visibility', 0.33s, 'ease-in-out');
	&.show {
		pointer-events: all;
		visibility: visible;
		@include opacity(1);
	}
	.PopupVerdict-title span{
		margin-left: -4.1em;
		color: #0A1E46;
		font-weight: 900;
	}
	.PopupVerdict-content {
		margin-left: 4.1em;
		position: relative;
	}
	.PopupVerdict-line {
		position:absolute;
		top: 11.91057em;
		left: 2.45em;
		width: 20.5em;
		height: 0.5em;
		background-color: $idle-color;
	}
	.PopupVerdict-optionText {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 10.87714em;
		height: 9.25928em;
		color: white;
		top: 2.62857em;
		&.option-1 {
			left: 0.85714em;
		}
		&.option-2 {
			left: 6.0714em;
		}
		&.option-3 {
			left: 11.21428em;
		}
		&.option-4 {
			left: 16.428571em;
		}
		&.option-5 {
			left: 21.64285714em;
		}
		&.option-6 {
			left: 26.785714em;
		}
		span {
			font-size: 0.85714em;
			height: 1.6207em;
			width: 12.7114em;
			transform: rotate(-39deg);
			text-align: center;
			color: #0A1E46;
			font-weight: 900;
		}
	}
	.PopupVerdict-optionOthers {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 2.985714em;
		height: 2.985714em;
		transform: translate(-0.895857em, -0.895857em);
		border: 0.21428em solid white;
		border-radius: 100%;
		background-color: $idle-color;
		color: white;
		top: 11.60714em;
		&.current {
			background-color: #C6103B;
		}
		&.option-1 {
			left: 2.4em;
		}
		&.option-2 {
			left: 7.52857em;
		}
		&.option-3 {
			left: 12.65714em;
		}
		&.option-4 {
			left: 17.7642em;
		}
		&.option-5 {
			left: 22.885714em;
		}
		&.option-6 {
			left: 28.085714em;
		}
		span {
			font-weight: 900;
		}
	}
	.PopupVerdict-option{
		position: absolute;
		display: flex;
		flex-direction: row;
		width: 1.194em;
		height: 1.194em;
		border-radius: 100%;
		background-color: $idle-color;
		color: white;
		top: 11.60714em;
		cursor: pointer;	
		&.current {
			background-color: #C6103B;
			width: 2.985714em;
			height: 2.985714em;
			transform: translate(-0.895857em, -0.895857em);
			border: 0.21428em solid white;
		}
		&.option-1 {
			left: 2.4em;
		}
		&.option-2 {
			left: 7.52857em;
		}
		&.option-3 {
			left: 12.65714em;
		}
		&.option-4 {
			left: 17.7642em;
		}
		&.option-5 {
			left: 22.885714em;
		}
		&.option-6 {
			left: 28.085714em;
		}
		
	}
	.PopupVerdict-text2 {
		position: absolute;
		left: calc(1.7285714em - 4.5em);
		top: 13.5em;
		color: black;
	}
	.PopupVerdict-text3 {
		position: absolute;
		left: calc(2.7285714em + 3em);
		top: 15em;

		color: black;
	}
	.PopupVerdict-btn2 {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background-color: $idle-color;
		border-radius: 1.7857em;
		width: 4.93285em;
		height: 1.7664em;
		top: 14.785714em;
		left: calc(4.571428em - 4.1em);
		color: white;
		span {
			font-size: 0.85714em;
			font-weight: 900;
		}
	}
	.PopupVerdict-btn {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background-color: $idle-color;
		border-radius: 1.7857em;
		width: 4.93285em;
		height: 1.7664em;
		top: 14.785714em;
		left: calc(31.9214em - 6.1em);
		color: white;
		span {
			font-size: 0.85714em;
			font-weight: 900;
		}
		&.blink {
			-webkit-animation: indication 1s infinite;
			animation: indication 1s infinite;
		}
	}
	
}