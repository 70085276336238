@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	position: relative;	
	width: 100%;
	height: 100vh;
	@include flex('center', 'center');
	.Loading-circles {
		width: 7.5em;
		@include flex('space-between', 'center');
		.Loading-circle {
			width: 2em;
			height: 2em;
			background-color: rgba(#fff, 1);
			border-radius: 2em;
		}
	}
}


@for $i from 1 to 4 {
	.Loading-circle:nth-child(#{$i}) {
		animation: loading 600ms ease #{300 * $i}ms alternate infinite;
	}
}
@keyframes loading {
	to {
		background-color: rgba(#fff, 0.1);
	}
}