@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}


@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@-webkit-keyframes displayDelay {
	0% {@include opacity(0)}
    99% {@include opacity(0)}
	100% {@include opacity(1)}
}
@keyframes displayDelay{
	0% {@include opacity(0)}
	99% {@include opacity(0)}
	100% {@include opacity(1)}
}




@-webkit-keyframes indication {
	0% {@include opacity(1);}
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes indication {
	0% {@include opacity(1);}
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}