@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Intro {
	position: relative;	
	width: 100%;
	height: 26.7857em;
	min-height: 100vh;
	-webkit-animation: fadeIn 1s ease-in 1;
	animation: fadeIn 1s ease-in 1;
	.Intro-cookiePopupWrapper {
		position: absolute;
		width: 100%;
		height: 26.7857em;
		top: 0;
		left: 0;
		@include flex('center','center');
		background: rgba(#111B34, 0.7);
		backdrop-filter: blur(1px);
		z-index: 99;
		.Intro-cookiePopup {
			background-color: white;
			width: 20em;
			height: 8em;
			border-radius: 0.5em;
			@include flex('center','center');
			span {
				padding-left: 1em;
				padding-right: 1em;
				color: black;
				text-align: center;
			}
		}
	}
	.Intro-availabilityStatementLink {
		position: absolute;
		left: 5.8em;
		top: 42.3em;
		font-size: 0.6em;
		text-decoration: underline;
		color: white;
		&:hover,
		&:active,
		&:visited {
			color: white;
		}
	}

	.Intro-logo {
		width: 6em;
		height: 1.5em;
		background-image: url('../../assets/images/logo-cgl.svg');
		background-size: 100% auto;
		background-position: bottom right;
		background-repeat: no-repeat;
		position: absolute;
		top: 22.5em;
		left: 24.5em;
		@include opacity(0.5);
		
	}
	.Intro-banner {
		width: 100%;
		height: 10.75em;
		background-image: url('../../assets/images/backgrounds/whiteoverlay.png'), url('../../assets/images/backgrounds/intro-1.jpg');
		background-size: cover, cover;
		background-position: top center, top center;
		@include flex($justify:"center", $align:"center", $direction:"column");
		.Intro-title {
			color: #111B34;
			span {
				font-family: 'Georgia';
				font-size: 2.4em;
				font-weight: bold;
			}
		}
		.Intro-subtitle {
			color: #111B34;
			span {
				font-family: 'Georgia';
				font-size: 1.3em;
			}
		}
		&::after {
			content: '';
			position: absolute;
			left: calc(23.8214285em - 2em);
			top: calc(10.75em - 1.5em);
			width: 4em;
			height: 3em;
			background-image: url('../../assets/images/logo-courts-of-denmark.svg');
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			z-index: 1;
		}
	}

	.Intro-content {
		color:white;
		background-color: #111B34;
		position: relative;
		width: 100%;
		height: 50%;
		padding: 1.85em 2em 1em 19.79em;
		background-image: url('../../assets/images/backgrounds/witnesses-3.jpg');
		background-size: 17em 10.57em;
		background-position: left 0.79em top 1.85em;
		background-repeat: no-repeat;
		.Intro-text {
			padding-right: 0em;
			margin-left: -1em;
			span {
				font-family: 'Roboto';
				font-size: 0.85em;
			}
		}
		.Intro-buttons {
			cursor: pointer;
			position: absolute;
			top: 12em;
			right: 1.75em;
			@include flex('center', 'center');
			.Intro-audioIcon {
				width: 2.21em;
				height: 1.57em;
				margin-left: 0.4em;
				background-size: contain;
				background-position: top left;
				background-image: url('/assets/images/icon-notmute-intro.svg');
				&.isMuted {
					background-image: url('/assets/images/icon-mute-intro.svg');
				}
			}
		}
	}
	
}