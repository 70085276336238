@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameAudio {
	position: absolute;		
	bottom: 2em;
	right: 3.4em;
	background-color: $idle-color;
	border-radius: 25px;
	width: 3.7857em;
	height: 1.5714em;
	@include flex('center', 'center');
	@include opacity(0);
	&.show {
		@include opacity(1);
	}

	.GameAudio-muteIcon {
		width: 1.1164em;
		height: 0.7614em;
		margin-left: .6em;
		background-image: url('../../assets/images/icon-mute.svg');
		background-position: center center;
		background-size: contain;
		&.muted {
			background-image: url('../../assets/images/icon-unmute.svg');

		}
	}
	.rhap_container {
		width: 1em;
		height: 0.7614em;
		.rhap_main {
			width: 100%;
			height: 100%;
		}
		.rhap_progress-section {
			display: none;
		}
		.rhap_controls-section {
			width: 100%;
			height: 100%;
			
			.rhap_additional-controls,
			.rhap_volume-controls {
				display: none;
			}
			
			.rhap_main-controls {
				width: 100%;
				height: 100%;
				.rhap_play-pause-button {
					width: 100%;
					height: 100%;
				}
				.injected-svg {
					width: 0.5628em;
					height: 0.66em;
				}
				button {
					cursor: pointer;
					border: none;
					background-color: transparent;
					margin: 0;
					padding: 0;
					@include flex('center', 'center');
				}
			}
		}
	}
}