@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Departure {
	-webkit-animation: fadeIn 1s ease-in 1;
	animation: fadeIn 2s ease-in 1;
	position: relative;
	width: 100%;
	height: 26.7857em;
	background-image: url('/assets/images/backgrounds/depature.jpg');
	background-size: contain;
	background-position: top left;
	.Departure-textbox {
		display: none;
		position: absolute;
		margin-top: 10.5em;
		margin-left: 14.785714em;
		z-index: 1;
		width: 18.0714em;
		height: 12.714em;
		padding: 0.942857em 1.17em 0.942857em 1.21em;
		border-radius: 1.14em;
		color:#475674;
		background-color: white;
		padding: 0.75em;
		overflow: hidden;
		span {
			font-size: 0.85em;
		}
		.Departure-title {
			padding-bottom: 0.25em;
			span {
				margin: 0;
				font-weight: 900;
				font-size: 1em;
				color: #0A1E46;
		
			}
		}
	}
}