@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Popup {
	position: relative;
	width: 100%;

	height: 100%;
	overflow: hidden;
	visibility: hidden;
	@include opacity(0);
	@include doubleTransition('opacity', 0.33s, 'ease-in-out', 'visibility', 0.33s, 'ease-in-out');
	pointer-events: none;
	&.show {
		pointer-events: all;
		visibility: visible;
		@include opacity(1);
	}
	.Popup-container {
		padding: 0.942857em 1.17em 0.942857em 1.21em;
		color: #475674;
		background-color: white;
		border-radius: 1.75em;
		z-index: 2;
		&.welcome {
			width: 20.857em;
			margin-top: 4.357em;
			margin-left: 13.2857em;
			height: 9.214em;
		}
		&.legalTeamProsecutor {
			width: 20.857em;
			margin-top: 4.357em;
			margin-left: 15.5em;
			height: 16.214em;
		}
		&.legalTeamDefendant {
			width: 20.857em;
			margin-top: 4.357em;
			margin-left: 10.8571em;
			height: 11.214em;
		}
		&.judgeAsks  {
			width: 20.857em;
			margin-top: 4.357em;
			margin-left: 13.42857em;
			height: 9.214em;
		}
		&.defendant-1 {
			width: 20.857em;
			margin-top: 6.9071em;
			margin-left: 10.1785em;
			height: 7.2857em;
		}
		&.defendant-2 {
			width: 20.857em;
			margin-top: 11.4357em;
			margin-left: 21.071em;
			height: 10.2857em;
		}
		&.defendant-4 {
			width: 20.857em;
			margin-top: 11.4357em;
			margin-left: 21.071em;
			&.answer2 {
				height: 10.9857em;
				.Carousel-indicators{ margin-top: 1em;}
				margin-top: 10.4357em;
				.Popup-header {height: 0;}
				strong {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
			&.answer1 {
				.Carousel-indicators{ margin-top: 1em;}
				height: 9.7857em;
				margin-top: 10.4357em;
				.Popup-header {height: 0;}
				strong {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
		}
		&.defendant-5 {
			width: 20.857em;
			margin-top: 17.5714em;
			margin-left: 19.6357em;
			height: 7.2857em;
		}
		&.defendant-6 {
			width: 20.857em;
			margin-top: 14.65em;
			margin-left: 1.5714em;
			height: 10.2857em;
		}
		&.defendant-8 {
			width: 20.857em;
			margin-top: 14.65em;
			margin-left: 1.5714em;
			height: 9.2857em;
			&.answer3 {
				margin-top: 12.65em;
				margin-left: 4.5714em;
				height: 12.2857em;
			}
			&.answer4 {
				height: 10.2857em;
			}
		}
		&.defendant-3, &.defendant-7,&.talkToJudge-2 {
			position: absolute;
			height: 19.705em;
			width: 26.35714em;
			top: 3.04285em;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			align-items: center;
			.Popup-header{
				justify-content: center;
				background-color: white;
				border-radius: 1.75em;
				padding: 0.942857em 1.17em 0.942857em 1.21em;
				width: 25.3571em;
				height: 4.714em;
				margin-left: calc(14.60714em + 10.42855em);
			}
			.Popup-body {
				margin-top: 1.35714em;
				width: 26.35714em;
				height: 12.6307em;
				margin-left: calc(11.60714em + 13.17857em);;
				.Popup-texts{
					width: 0;
					height: 0;
				}
				.Popup-questions {
					display: flex;
					height: 100%;
					width: 100%;
					
					.Popup-question:last-child {
						margin-left: 1.9285em;
					}
					.Popup-question {
						display: flex;
						flex-direction: column;
						flex: 1;
						width: 12.2142em;
						height: 12.63em;
						background-color: white;
						margin-bottom: 1em;
						border-radius: 1.75em;
						padding: 0.942857em 1.17em 0.942857em 1.21em;
						
						.Popup-questionButton {
							&.blink {
								-webkit-animation: indication 1s infinite;
								animation: indication 1s infinite;
							}
							&.chosen  {
								background-color: #0A1F45;
								color: white;
							}
							margin-top: auto;
							background-color: white;
							border: 0.0714em solid #0A1F45;
							width: 3.7857em;
							height: 1.5714em;
							color:  #0A1F45;
							border-radius: 1.75em;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							span {
								font-weight: 900;
							}
						}
					}
					
				}
			}
		}
		&.defendant-3 {
			.Popup-chosen {
				position: absolute;
				display: flex;
				flex-direction: column;
				background-color: white;
				border-radius: 1.75em;
				padding: 0.942857em 1.17em 0.942857em 1.21em;
				width: 20.857em;
				top: calc(6.7642em - 3.54285em);
				left: 10.1785em;
				height: auto;
			}
			.Popup-chosen-question {
				span {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}

		}

		&.defendant-7 {
			.Popup-chosen {
				position: absolute;
				display: flex;
				flex-direction: column;
				background-color: white;
				border-radius: 1.75em;
				padding: 0.942857em 1.17em 0.942857em 1.21em;
				width: 20.857em;
				top: calc(17.5714em - 3.54285em);
				left: 19.6357em;
				height: 5.2857em;
			}
			.Popup-chosen-question {
				span {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
			
		}

		&.witnesses-1 {
			width: 23.1428em;
			margin-top:9.5em;
			margin-left: 12.857em;
			height: 8.5714em;
		}
		&.witnesses-2 {
			width: 20.85714em;
			margin-top: 8.007em;
			margin-left: 11.25em;
			height: 10.2857em;
		}
	
		&.witnesses-3 {
			width: 20.85714em;
			margin-top: 8.007em;
			margin-left: 11.25em;
			height: 7.2857em;
		}
		
		&.witnesses-4 {
			width: 20.85714em;
			margin-top: 12.15em;
			margin-left: 20.8857em;
			height: 11.2857em;
		}
	
		&.witnesses-5 {
			width: 20.85714em;
			margin-top: 16.7857em;
			margin-left: 20.9214em;
			height: 5.2857em;
		
		}
		&.witnesses-6 {
			width: 20.85714em;
			margin-top: 14.8928em;
			margin-left: 2.96428em;
			height: 10.2857em;
		}
		&.witnesses-7 {
			width: 20.85714em;
			margin-top: 10.8928em;
			margin-left: 5.96428em;
			height: 7.2857em;
			
		}

		&.documents-1 {
			width: 20.85714em;
			margin-top: 8.357em;
			margin-left: 13.85714em;
			height: 6.2857em;
		}
		&.documents-2 {
			width: 20.85714em;
			margin-top: 8.357em;
			margin-left: 13.85714em;
			height: 12.2857em;
		}
		&.documents-3 {
			width: 36.21428em;
			margin-top: 4.7142em;
			margin-left: 6.1428em;
			height: 20.214em;
			.Popup-texts, .Popup-text {
				height: 0em;
			}
			.Popup-video {
				top: 8em;
				position: absolute;
				width: 34.21428em;
				height: 15.214em;
			}
		}
		&.personal {
			width: 20.85714em;
			margin-top: 7.8928em;
			margin-left: 13.96428em;
			height: 8.2857em;
		}
		&.legalTeamProsecutor2 {
			width: 20.85714em;
			margin-top: 4.07142em;
			margin-left: 16.64285em;
			height: 14.21428em;
			.Carousel-indicators {
				margin-top: 1.5em;
			}
		}
		&.legalTeamDefendant2 {
			width: 20.85714em;
			margin-top: 3.8571em;
			margin-left: 10.8571em;
			height: 16.71428em;
			.Carousel-indicators {
				margin-top: 1em;
			}
		}
		&.defendant2-1 {
			width: 20.85714em;
			margin-top: 4.357em;
			margin-left: 9.1428em;
			height: 6.71428em;
		}

		&.defendant2-2 {
			width: 20.85714em;
			margin-top: 4.357em;
			margin-left: 9.1428em;
			height: 10.71428em;
		}
		&.defendant2-3 {
			width: 20.85714em;
			margin-top: 4.357em;
			margin-left: 9.1428em;
			height: 7.21428em;
		}
		
		&.talkToJudge-1 {
			width: 23.1428em;
			margin-top: 5.85714em;
			margin-left: 17.28571em;
			height: 12.5714em;
		}

		&.talkToJudge-2 {
			position: absolute;
			height: 19.705em;
			width: 26.35714em;
			top: 3.54285em;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			align-items: center;
			.Popup-header{
				justify-content: center;
				background-color: white;
				border-radius: 1.75em;
				padding: 0.942857em 1.17em 0.942857em 1.21em;
				width: 25.3571em;
				height: 3.714em;
				margin-left: calc(14.60714em + 10.42855em);
			}
			.Popup-body {
				margin-top: 1.35714em;
				width: 26.35714em;
				height: 12.6307em;
				margin-left: calc(11.60714em + 13.17857em);;
				.Popup-texts{
					width: 0;
					height: 0;
				}
				.Popup-questions {
					display: flex;
					height: 100%;
					width: 100%;
					
					.Popup-question:last-child {
						margin-left: 1.9285em;
					}
					.Popup-question {
						display: flex;
						flex-direction: column;
						flex: 1;
						width: 12.2142em;
						height: 12.63em;
						background-color: white;
						margin-bottom: 1em;
						border-radius: 1.75em;
						padding: 0.942857em 1.17em 0.942857em 1.21em;
						
						.Popup-questionButton {
							&.blink {
								-webkit-animation: indication 1s infinite;
								animation: indication 1s infinite;
							}
							&.chosen  {
								background-color: #0A1F45;
								color: white;
							}
							margin-top: auto;
							background-color: white;
							border: 0.0714em solid #0A1F45;
							width: 3.7857em;
							height: 1.5714em;
							color:  #0A1F45;
							border-radius: 1.75em;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							span {
								font-weight: 900;
							}
							
						}
					}
					
				}
			}
		}
		&.talkToJudge-2 {
			.Popup-chosen {
				position: absolute;
				display: flex;
				flex-direction: column;
				background-color: white;
				border-radius: 1.75em;
				padding: 0.942857em 1.17em 0.942857em 1.21em;
				width: 23.857em;
				top: calc(10.5714em - 3.54285em);
				left: 15.6357em;
				height: 4.2857em;
			}
			.Popup-chosen-question {
				span {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
			
		}
		&.talkToJudge-3 {
			width: 23.1428em;
			margin-top: 5.85714em;
			margin-left: 8.7142em;
			height: 12.5714em;
			&.answer2 {
				.Carousel-indicators{ margin-top: 1em;}
				.Popup-header {height: 0;}
				strong {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
			&.answer1 {
				height: 14.0714em;
				.Carousel-indicators{ margin-top: 2.5em;}
				.Popup-header {height: 0;}
				strong {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
			}
		}
		&.talkToLayJudge {
			width: 23.1428em;
			margin-top: 5.85714em;
			margin-left: 8.7142em;
			height: 12.5714em;
			.Popup-back {
				position: absolute;
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 1.78571em;
				top: 18.785714em;
				left: 24.15714em;
				width: 7em;
				height: 1.5em;
				cursor: pointer;
				z-index: 1;
			}
		}
		&.sentencing-1,
		&.sentencing-2 {
			width: 18.0714em;
			margin-top: 5.785714em;
			margin-left: 14.785714em;
			height: 16.714em;
		}
	

		.Popup-header {
			height: 2em;
			@include flex('space-between', 'center');
			
			.Popup-title span:first-child {
				margin: 0;
				font-weight: 900;
				font-size: 1em;
				color: #0A1E46;
			}
			.Popup-subtitle {
				margin: 0;
				font-weight: normal;
				font-size: 1em;
				color: #0A1E46;
			}
		}
		.Popup-question-title span{
			margin: 0;
			font-weight: 900;
			font-size: 1em;
			color: #0A1E46;
		}
		

		.Popup-body {
			width: 100%;
			height: 100%;
			.Popup-texts {
				width: 100%;
				height: 100%;
				.Popup-text {
					width: 100%;
					height: 100%;
					p {
						display: inline-block;
						white-space: normal;
						
						width: 100%;
						height: 100%;
					}
				}
			}
			.Popup-texts p {
				
				margin: 0;
				font-weight: 400;
				font-size: 1em;
				color: #475674;
			}
		}
	}
}