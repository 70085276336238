@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stats {
	font-size: calc(18 * ((100vw / 1280)));
	height: 100%;
	width: 100%;
	color: white;
	background-color:#111B34;
	padding: 2em 4em;
	.Stats-logoutBtn {
		position: absolute;
		top: 1em;
		left: 1em;
		background-color: white;
		color: black;
		border-radius: 0.55em;
		padding: 0.25em 0.5em;
		cursor: pointer;
		&:hover {background-color: #efefef;}
	}

	.Stats-title {
		font-size: 2em;
		text-align: center;
	}

	.Stats-period {
		font-size: 1.1em;
		font-weight: bold;
		text-align: center;
		margin: 2em 0;
		@include flex('center', 'center');
		.Stats-periodTo {
			margin: 0 1em;
		}
		.react-datepicker-wrapper {
			position: relative;
			width: 10em;
			input {
				position: relative;
				width: 100%;
				height: 2em;
				font-size: 0.9em;
				border-radius: 0.6em;
				border: 1px solid #88A1AA;
				padding: 1em;
				@include placeholderColor(#B4B4B4);

			}
		}
	}

	.Stats-section {
		.Stats-sectionRow {
			span {
				display: inline-block;
				vertical-align: middle;
				&:first-of-type {
					font-weight: bold;
					width: 12em;
				}
			}
			
		}
	}

	.Stats-histogram {
		width: 50em;
		padding-bottom: 2em;
		.Stats-histogramCols {
			position: relative;
			border-bottom: 2px solid white;
		}
		.Stats-histogramCol {
			position: relative;
			height: 15em;
			width: 7em;
			display: inline-block;
			vertical-align: bottom;
			
		}
		.Stats-histogramColFill {
			position: absolute;
			left: 0.5em;
			right: 0.5em;
			bottom: 0;
			color: #0A1E46;
			background-color: white;
	
			text-align: center;
			span {
				font-size: 0.75em;
				color: #0A1E46;
				background-color: rgba(white, 0.8);
					
			}
		}
		.Stats-historgramLabel {
			display: inline-block;
			width: 7em;
			text-align: center;
			padding-top: 0.25em;
			span {
				font-size: 0.8em;
			}
			
		}

		&.verdict {
			.Stats-histogramCol {
				width: 10em;
			}
			.Stats-historgramLabel {
				width: 10em;
			}
		}
	}	
}