@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Carousel {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	.Carousel-inner {
		width: 100%;
		height: 100%;
		white-space: nowrap;
		transition: transform 0.3s;
		position: relative;
		vertical-align: top;
		.Carousel-item {
			vertical-align: top;
			width: 100%;
			height: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;

		}
	}

	.Carousel-indicators {
		z-index: 10;
		@include no-select();
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4.8692em;
		height: 1.6485em;
		margin-top: auto;
		padding-bottom: 2.5em;
		.Carousel-status {
			width: 80%;
			height: 1.357em;
			padding-right: 0.62857em;
			padding-left: 0.5714em;
			padding-top: 0.1em;
			color: #0A1E46;
			font-weight: 900;
		}
		.Carousel-indicatorWrap {
			width: 1.01214em;
			height: 1.6485em;
			.Carousel-indicator {
				width: 100%;
				height: 100%;
				background-image: url('../../assets/images/icon-prev.svg');
				background-size: 100% auto;
				background-position: center;
				cursor: pointer;
				&.next {
					background-image: url('../../assets/images/icon-next.svg');
					&.blink {
						-webkit-animation: indication 1s infinite;
						animation: indication 1s infinite;
					}
				}

			}
		}
	}
}





