@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Button {
	display: inline-block;
	width: 10em;
	height: 2.25em;
	padding: 0 1em;
	font-weight: bold;
	text-align: center;
	color: white;
	background-color: #B7002D;
	border-radius: 1.75em;
	cursor: pointer;
	@include no-select();
	@include flex('center', 'center');
	&:hover,
	&:focus,
	&:active {
		background-color: darken(#B7002D, 5%);
	}
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		background-image: url('../../assets/images/icon-loading-2.svg');
		background-size: auto 80%;
		background-position: center right;
	}
	&.reset,
	&.logout {
		position: absolute;
		top: 0.5em;
		left: 0.5em;
		width: 5em;
		height: 1.25em;
		cursor: pointer;
	}
	&.logout {
		width: 6em;
		height: 1.5em;
	}
	&.cookies {
		position: absolute;
		right: 2em;
		top: 2em;
		width: 7.5em;
		height: 2em;
		color: white;
		font-weight: normal;
		background-color: black;
		@include flex('center', 'center');
	}
	&.startButton {
		width: 7em;
		height: 1.75em;
		color: #F7F8FB;
		background-color: #E1232D;
		text-transform: uppercase;
	}

	&.prev,
	&.next {
		position: absolute;
		top: calc(50% - 1.425em);
		width: 2.85em;
		height: 2.85em;
		padding: 0;
		background-size: contain;
		background-position: center;
		background-color: transparent;
		cursor: pointer;	
		&.popup{
			position: absolute;
			z-index: 90;
		}
	}
	&.prev {
		left: 1.5em;
		background-image: url('/assets/images/prev.svg');	
	}

	&.next {
		left: 44.0357em;
		background-image: url('/assets/images/next.svg');	
	}
	&.goToStart{
		position: absolute;
		width: 10.22214em;
		height: 2.005714em;
		left: 31.5785714em;
		top:18.785714em;
		background-color: white;
		color: #0A1E46;
		span {
			font-size: 0.85714em;
			font-weight: 900;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 20%);
		}
	}
	&.becomeJudge {
		position: absolute;
		width: 10.22214em;
		height: 2.005714em;
		left: 20.0642em;
		top:18.785714em;
		background-color: white;
		color: #0A1E46;
		span {
			font-size: 0.85714em;
			font-weight: 900;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 20%);
		}
	}
	&.becomeJudge2 {
		position: absolute;
		width: 10.22214em;
		height: 2.005714em;
		left: 8.7142857em;
		top: 18.785714em;
		background-color: white;
		color: #0A1E46;
		span {
			font-size: 0.85714em;
			font-weight: 900;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 20%);
		}
	}
}