@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/animations';
html {
	position: relative;
	font-size: 2.1vw; // 14px at screen width ~667px
}

body {
	overflow-x: hidden;
	&::-webkit-scrollbar {display: none;}
	position: relative;
	font-size: 1em;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	color: white;
	background-color: #111B34;
	touch-action: manipulation; // disables double-tap gestur -> no click delay by the browser
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-webkit-overflow-scrolling: touch;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
	background-repeat: no-repeat;
}

svg,
img {
	width: 100%;
	height: 100%;
}

button {
	font-size: 1em;
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	@include appearance(none);
}
