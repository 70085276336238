@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Arrival {
	position: relative;
	width: 100%;
	height: 26.7857em;
	overflow: hidden;

	background-image: url('/assets/images/backgrounds/arrival.jpg');
	background-position: top left;
	background-size: contain;
	@include origin(0, 0);
	-webkit-animation: fadeIn 1s ease-in 1;
	animation: fadeIn 1s ease-in 1;
	
	
	

	.Arrival-video {
		position: absolute;
		width: calc(137.1428em/2);
		height: calc(21.785714em/2);
		left: -1.5em;
		background-size: contain;
		background-position: top left;
		mix-blend-mode: darken;
		@include no-select();		
	}
	.Arrival-body {
		@include no-select();
		position: absolute;
		top: 8.214em;
		left: 25em;
		z-index: 1;
		width: 16.25em;
		border-radius: 1.14em;
		color:#0A1E46;
		background-color: white;
		padding: 0.75em;

		&.body-0 {
			height: auto;
			.Carousel-indicators{position: absolute;
			top: 90%;}
		}
		
		.Arrival-textbox {
			width: 100%;
			height: 100%;

			.Arrival-text {
				width: 100%;
				height: 100%;
				p {
					display: inline-block;
					white-space: normal;
					width: 100%;
					height: 100%;
					text-align: start;
					margin-top: 0;
					margin-bottom: 0;
					strong {
						margin: 0;
						font-weight: 900;
						font-size: 1em;
						color: #0A1E46;
					}
				}
			}
			
		}
		.Arrival-texts p {
			margin: 0;
			font-weight: 400;
			font-size: 1em;
			color: #475674;
		}
		
		
	}
	
	.Arrival-audioContainer {
		position: absolute;
		top: 23.285714em;
		left: 40.42857em;
	}
}