@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.StatsLogin {
	height: 100%;
	width: 100%;
	background-color:#111B34;
	font-size: calc(18 * ((100vw / 1280)));
	@include flex('center', 'center');
	.StatsLogin-container {
		width: 14.6em;
		height: 15em;
		padding: 1em 1.25em;
		border-radius: 0.6em;
		margin: 0 1em;
		text-align: left;
		color: #0A1E46;
		background-color: white;
		.StatsLogin-title {
			
			margin-bottom: 1em;
			text-align: center;
			span {
				font-size: 1.25em;
				font-weight: 700;
			}
		}
		.StatsLogin-form {
			position: relative;
			input {
				display: block;
				width: 100%;
				height: 2em;
				border: none;
				border-radius: 0.5em;
				padding: 0.5em 1em;
				margin: auto;
				margin-bottom: 1em;
				border: 1px solid #0A1E46;
				color: #0A1E46;
				font-weight: normal;
				&::placeholder {color: rgba(#0A1E46, 0.5);}
			}
			.Button {
				width: 100%;
				height: 2em;
				border-radius: 0.5em;
				margin-bottom: 1em;
				padding: 0;
				color: white;
				background-color: #B7002D;
				text-transform: none;
				@include flex('center', 'center');
				&:hover {
					background-color: darken(#B7002D, 3%);
				}
				&--isLoading {
					background-image: url('../../assets/images/icon-loading-2.svg');
					background-size: auto 80%;
					background-position: center right;
					background-repeat: no-repeat;
				}
			}
		}
	}
}

.StatsLogin-errorMessage {
	height: 1.25em;
	margin: 0.25em;

	text-align: left;
	span {
		font-size: 0.75em;
		color: #B7002D;
	}
}