@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CourtRoom {
	position: relative;
	width: 100%;
	height: 26.7857em;
	overflow: hidden;
	
	.CourtRoom-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: contain, contain, contain;
		background-position: top left, top left, top left;
		background-repeat: no-repeat, no-repeat, no-repeat;
		
		@include origin(0, 0);
		.CourtRoom-background-paper, .CourtRoom-background-clock {
			position: inherit;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: contain, contain;
			background-position: top left;
			background-repeat: no-repeat;
		}
		&.votingRoom {
			&.animation-talkToJudge-1 {
				@include three-backgrounds-transition(talkToJudge, 
				'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg',
				1.35, 1.35, 1.35,
				-34%, 0, 0, 0, 0, 0,
				);
				-webkit-animation: talkToJudge 5s ease 1 forwards;
				animation: talkToJudge 5s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/voting.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/voting.png')
				}
			}
		
			&.animation-talkToJudge-3 {
				@include three-backgrounds-transition(talkToLayJudge, 
				'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg',
				1.35, 1.35, 1.35,
				0, 0, -34%, 0, -34%, 0,
				);
				-webkit-animation: talkToLayJudge 5s ease 1 forwards;
				animation: talkToLayJudge 5s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/voting.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/voting.png')
				}
			}
			&.animation-voting2 {
				@include three-backgrounds-transition(courtRoomToframe, 
				'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/welcome-1.jpg',
				1.6, 1, 1,
				-60%, -13%, 0, 0, 0, 0,
				);
				@include three-backgrounds-transition(courtRoomToVoting2, 
				'backgrounds/welcome-1.jpg', 'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg',
				1,  1.35, 1.35,
				0, 0, -34%, 0, -34%, 0,
				);
				-webkit-animation: courtRoomToframe 3s ease 1 forwards,courtRoomToVoting2 3s 3s ease 1 forwards;
				animation: courtRoomToframe 3s ease 1 forwards,courtRoomToVoting2 3s 3s ease 1 forwards;
				
				.CourtRoom-background-clock {
					@include change-background(courtRoomToframe-aditional);
					-webkit-animation: courtRoomToframe-aditional 3.1s ease 1 forwards;
					animation: courtRoomToframe-aditional 3.1s ease 1 forwards;
	
				}
				
			}
			&.animation-voting {
				@include three-backgrounds-transition(courtRoomToVoting, 
				'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg', 'backgrounds/voting-1.jpg',
				1.35,  1, 1,
				-34%, 0, 0, 0, 0, 0,
				);
				-webkit-animation: courtRoomToVoting 2s ease 1 forwards;
				animation: courtRoomToVoting 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/voting.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/voting.png')
				}
			}
			&.static-voting2 {
				@include translateAndScale(-34%, 0, 1.35);
				background-image: url('/assets/images/backgrounds/clock/voting.png'),url('/assets/images/backgrounds/paper/voting.png'),url('/assets/images/backgrounds/voting-1.jpg');
			}
			&.static-voting {
				background-image: url('/assets/images/backgrounds/clock/voting.png'),url('/assets/images/backgrounds/paper/voting.png'),url('/assets/images/backgrounds/voting-1.jpg');
			}
			&.static-talkToJudge-1,
			&.static-talkToJudge-2,
			&.animation-talkToJudge-2 {
				@include translateAndScale(0, 0, 1.35);
				background-image: url('/assets/images/backgrounds/clock/voting.png'),url('/assets/images/backgrounds/paper/voting.png'),url('/assets/images/backgrounds/voting-1.jpg');
			}
			&.static-talkToJudge-3 {
				@include translateAndScale(-34%, 0, 1.35);
				background-image: url('/assets/images/backgrounds/clock/voting.png'),url('/assets/images/backgrounds/paper/voting.png'),url('/assets/images/backgrounds/voting-1.jpg');
			}	
		}

		&.static{
			&-welcome {
				background-image: url('/assets/images/backgrounds/clock/welcome.png'),url('/assets/images/backgrounds/paper/welcome.png'), url('/assets/images/backgrounds/welcome-3.jpg');
			}

			&-legalTeamProsecutor
			{
				background-image: url('/assets/images/backgrounds/clock/legal-team-prosecutor.png'),url('/assets/images/backgrounds/paper/legal-team-prosecutor.png'),url('/assets/images/backgrounds/legal-team-prosecutor.jpg');
				@include translateAndScale(0, 0, 1.37);
			}
			&-legalTeamProsecutor2 {
				background-image: url('/assets/images/backgrounds/clock/legal-team-prosecutor2.png'),url('/assets/images/backgrounds/paper/legal-team-prosecutor2.png'),url('/assets/images/backgrounds/documents-prosecutor.jpg');
				@include translateAndScale(0, 0, 1.37);
			}

			&-legalTeamDefendant
			{
				background-image: url('/assets/images/backgrounds/clock/legal-team-defendant.png'),url('/assets/images/backgrounds/paper/legal-team-defendant.png'),url('/assets/images/backgrounds/legal-team-defendant.jpg');
				@include translateAndScale(-37%, 0, 1.37);
			}
			&-legalTeamDefendant2 {
				background-image: url('/assets/images/backgrounds/clock/legal-team-defendant2.png'),url('/assets/images/backgrounds/paper/legal-team-defendant2.png'),url('/assets/images/backgrounds/legal-team-defendant2-legal-team-defendant.jpg');
				@include translateAndScale(-37%, 0, 1.37);
			}

			&-witnesses-1 {
				@include translateAndScale(0, 0, 1);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'), url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/welcome-3.jpg');
			} 

			&-witnesses-2 {
				@include translateAndScale(0, 0, 1);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-3.jpg');
			}
			&-witnesses-3 {
				@include translateAndScale(0, 0, 1.37);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-prosecutor.jpg');
			}

			
			&-witnesses-4 {
				@include translateAndScale(0, 0, 1.37);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-witnesses.jpg');
			} 
			&-witnesses-5 {
				@include translateAndScale(-37%, 0, 1.37);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-legal-team-defendant.jpg');
			}
			&-witnesses-6 {
				@include translateAndScale(-37%, 0, 1.37);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-witnesses.jpg');
			}
			&-witnesses-7 {
				@include translateAndScale(-37%, 0, 1.37);
				background-image: url('/assets/images/backgrounds/clock/witnesses.png'),url('/assets/images/backgrounds/paper/witnesses.png'),url('/assets/images/backgrounds/witnesses-3.jpg');
			}
			&-documents-2 {
				background-image: url('/assets/images/backgrounds/clock/documents.png'),url('/assets/images/backgrounds/paper/documents.png'),url('/assets/images/backgrounds/documents-prosecutor.jpg');
			}
			&-documents-1,
			&-personal {
				background-image: url('/assets/images/backgrounds/clock/documents.png'),url('/assets/images/backgrounds/paper/documents.png'),url('/assets/images/backgrounds/welcome-3.jpg');
			}
			&-documents-3 {
				@include translateAndScale(-170%, -170% , 2.7);
				background-image: url('/assets/images/backgrounds/clock/documents.png'),url('/assets/images/backgrounds/paper/documents.png'),url('/assets/images/backgrounds/welcome-3.jpg');
			}
			&-judgeAsks {
				background-image: url('/assets/images/backgrounds/clock/judge-asks.png'),url('/assets/images/backgrounds/paper/judge-asks.png'), url('/assets/images/backgrounds/welcome-3.jpg');
			}
			&-defendant-1,
			&-defendant-3 {
				@include translateAndScale(0,0,1.37);
				background-image: url('/assets/images/backgrounds/clock/defendant.png'),url('/assets/images/backgrounds/paper/defendant.png'),url('/assets/images/backgrounds/defendant-prosecutor.jpg');
			}

			&-defendant-2,
			&-defendant-4 {
				@include translateAndScale(0,0,1.37);
				background-image: url('/assets/images/backgrounds/clock/defendant.png'),url('/assets/images/backgrounds/paper/defendant.png'),url('/assets/images/backgrounds/defendant-defendant.jpg');
			}
			
			
			&-defendant-5,
			&-defendant-7 {
				@include translateAndScale(-37%, 0 , 1.37);
				background-image: url('/assets/images/backgrounds/clock/defendant.png'),url('/assets/images/backgrounds/paper/defendant.png'),url('/assets/images/backgrounds/defendant-legal-team-defendant.jpg');
				
			}
			&-defendant-6,
			&-defendant-8 {
				@include translateAndScale(-37%, 0 , 1.37);
				background-image: url('/assets/images/backgrounds/clock/defendant.png'),url('/assets/images/backgrounds/paper/defendant.png'),url('/assets/images/backgrounds/defendant-defendant.jpg');
				
			}
			&-defendant2-2 {
				@include translateAndScale(-60%, -13% , 1.6);
				background-image: url('/assets/images/backgrounds/clock/defendant2.png'),url('/assets/images/backgrounds/paper/defendant2.png'),url('/assets/images/backgrounds/defendant2-defendant.jpg');
			}
		
			&-defendant2-1,
		
			&-defendant2-3{
				@include translateAndScale(-60%, -13% , 1.6);
				background-image: url('/assets/images/backgrounds/clock/defendant2.png'),url('/assets/images/backgrounds/paper/defendant2.png'),url('/assets/images/backgrounds/welcome-3.jpg');
			}
			
			&-sentencing-1,
			&-sentencing-2 {
				background-image: url('/assets/images/backgrounds/clock/sentencing.png'),url('/assets/images/backgrounds/paper/sentencing.png'),url('/assets/images/backgrounds/sentencing-2.jpg');
			}

			
		}

		&.animation {
			
			&-welcome {	
				@include three-backgrounds-transition(courtRoomWelcome, 'backgrounds/welcome-1.jpg','backgrounds/welcome-2.jpg', 'backgrounds/welcome-3.jpg');
				-webkit-animation: courtRoomWelcome 3s linear forwards;
				animation: courtRoomWelcome 3s linear forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/welcome.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/welcome.png')
				}
				
			}

			&-legalTeamProsecutor{
				@include three-backgrounds-transition(legalTeamProsecutor, 
					'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/legal-team-prosecutor.jpg',
					1, 1.37, 1.37,
					0,0,0,0,0,0,
				);
				-webkit-animation: legalTeamProsecutor 2s ease 1 forwards;
				animation: legalTeamProsecutor 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/legal-team-prosecutor.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/legal-team-prosecutor.png')
				}
			}
			&-legalTeamProsecutor2 {
				@include three-backgrounds-transition(legalTeamProsecutor2, 
					'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/documents-prosecutor.jpg',
					1, 1.37, 1.37,
					0,0,0,0,0,0,
				);
				-webkit-animation: legalTeamProsecutor2 2s ease 1 forwards;
				animation: legalTeamProsecutor2 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/legal-team-prosecutor2.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/legal-team-prosecutor2.png')
				}
			}

			&-legalTeamDefendant
			{
				@include three-backgrounds-transition(legalTeamDefendant, 
				'backgrounds/legal-team-prosecutor.jpg', 'backgrounds/legal-team-prosecutor.jpg', 'backgrounds/legal-team-defendant.jpg',
				1.37, 1.37, 1.37,
				0, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: legalTeamDefendant 3s ease 1 forwards;
				animation: legalTeamDefendant 3s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/legal-team-defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/legal-team-defendant.png')
				}
			}
			&-legalTeamDefendant2 {
				@include three-backgrounds-transition(legalTeamDefendant2-new, 
				'backgrounds/documents-prosecutor.jpg', 'backgrounds/legal-team-defendant2-legal-team-defendant.jpg', 'backgrounds/legal-team-defendant2-legal-team-defendant.jpg',
				1.37, 1.37, 1.37,
				0, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: legalTeamDefendant2-new 3s ease 1 forwards;
				animation: legalTeamDefendant2-new 3s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/legal-team-defendant2.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/legal-team-defendant2.png')
				}
			}

			&-witnesses-1 {
				@include two-backgrounds-transition (witnesses-1, 'backgrounds/defendant-2.jpg', 'backgrounds/defendant-1.jpg',
				1.37, 1,
				-37%, 0, 0, 0,
				);
				@include two-backgrounds-transition(witnesses-8, 'backgrounds/defendant-1-3.jpg','backgrounds/welcome-3.jpg',
				1, 1,
				0, 0, 0, 0,
				);
			
				-webkit-animation: witnesses-1 linear 1s 1 forwards, witnesses-8 2.5s linear 0.9s 1 forwards;
				animation: witnesses-1 linear 1s 1 forwards, witnesses-8 2.5s linear 0.9s 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			}
			&-witnesses-2 {
				@include three-backgrounds-transition(witnesses-2, 
				'backgrounds/witnesses-1.jpg', 'backgrounds/witnesses-2.jpg', 'backgrounds/witnesses-3.jpg',
				1, 1, 1,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: witnesses-2 3s ease forwards;
				animation: witnesses-2 3s ease forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			}
			&-witnesses-3 {
				@include three-backgrounds-transition(witnesses-3, 
				'backgrounds/witnesses-3.jpg', 'backgrounds/witnesses-3.jpg', 'backgrounds/witnesses-prosecutor.jpg',
				1, 1.37, 1.37,
				0, 0, -18.5%, 0, 0, 0,
				);
				-webkit-animation: witnesses-3 3s ease forwards; 
				animation: witnesses-3 3s ease forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			}

			&-witnesses-4 {
				@include three-backgrounds-transition(witnesses-4, 
				'backgrounds/witnesses-prosecutor.jpg', 'backgrounds/witnesses-witnesses.jpg', 'backgrounds/witnesses-witnesses.jpg',
				1.37, 1.37, 1.37,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: witnesses-4 2s ease forwards; 
				animation: witnesses-4 2s ease forwards; 
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			} 
			&-witnesses-5 {
				@include three-backgrounds-transition(witnesses-5, 
				'backgrounds/witnesses-3.jpg', 'backgrounds/witnesses-3.jpg', 'backgrounds/witnesses-legal-team-defendant.jpg',
				1.37, 1.37, 1.37,
				0, 0, -37%, 0, -37%, 0 
				,
				);
				-webkit-animation: witnesses-5 2s ease 1 forwards;
				animation: witnesses-5 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			}
			&-witnesses-6 {
				@include three-backgrounds-transition(witnesses-6, 
				'backgrounds/witnesses-legal-team-defendant.jpg', 'backgrounds/witnesses-witnesses.jpg', 'backgrounds/witnesses-witnesses.jpg',
				1.37, 1.37, 1.37,
				-37%, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: witnesses-6 2s ease forwards; 
				animation: witnesses-6 2s ease forwards; 
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			}
			&-witnesses-7 {
				@include three-backgrounds-transition(witnesses-7, 
				'backgrounds/witnesses-witnesses.jpg', 'backgrounds/witnesses-3.jpg', 'backgrounds/witnesses-3.jpg',
				1.37, 1.37, 1.37,
				-37%, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: witnesses-7 2s ease forwards; 
				animation: witnesses-7 2s ease forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/witnesses.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/witnesses.png')
				}
			} 
			&-documents-1 {
				@include three-backgrounds-transition(documents-1, 
				'backgrounds/witnesses-3.jpg', 'backgrounds/documents-1.jpg', 'backgrounds/welcome-3.jpg',
				1.37, 1, 1,
				-37%, 0, 0, 0, 0, 0,

				);
				-webkit-animation: documents-1 3s ease 1 forwards;
				animation: documents-1 3s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/documents.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/documents.png')
				}
			}
			&-documents-2 {
				@include three-backgrounds-transition(documents-2, 
				'backgrounds/welcome-3.jpg', 'backgrounds/documents-prosecutor.jpg', 'backgrounds/documents-prosecutor.jpg',
				1, 1, 1,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: documents-2 3s ease 1 forwards;
				animation: documents-2 3s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/documents.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/documents.png')
				}
			}
			&-documents-4,
			&-documents-3 {
				@include three-backgrounds-transition(documents-3, 
				'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg',
				1, 2.7, 2.7,
				0, 0, -170%, -170%, -170%, -170%,
				);
				-webkit-animation: documents-3 3s ease 1 forwards;
				animation: documents-3 3s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/documents.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/documents.png')
				}
			}
			&-personal {
				@include three-backgrounds-transition(test5, 
				'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg',
				2.7, 1, 1,
				-170%, -170%, 0, 0, 0, 0,
				);
				-webkit-animation: test5 2s ease 1 forwards;
				animation: test5 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/documents.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/documents.png')
				}
			}
			
			&-judgeAsks {
				@include three-backgrounds-transition(judgeAsks, 
				'backgrounds/legal-team-defendant.jpg', 'backgrounds/legal-team-defendant.jpg', 'backgrounds/welcome-3.jpg',
				1.37, 1, 1,
				-37%, 0, 0, 0, 0, 0,
				);
				-webkit-animation: judgeAsks 2s ease 1 forwards;
				animation: judgeAsks 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/judge-asks.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/judge-asks.png')
				}
			}
			&-defendant-1 {
				@include three-backgrounds-transition(defendant-1,'backgrounds/welcome-3.jpg','backgrounds/defendant-1-2.jpg','backgrounds/defendant-1.jpg',
				1, 1, 1,
				0, 0, 0, 0, 0, 0);
				
				@include three-backgrounds-transition(defendant-2, 
				'backgrounds/defendant-1.jpg', 'backgrounds/defendant-1.jpg', 'backgrounds/defendant-prosecutor.jpg',
				1, 1.37, 1.37,
				0, 0, -18.5%, 0, 0, 0,
				);
				-webkit-animation: defendant-1 2s ease 1 forwards, defendant-2 2s 1.9s ease 1 forwards;
				animation: defendant-1 2s ease 1 forwards, defendant-2 2s 1.9s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant.png')
				}
			}
			&-defendant-2,&-defendant-4 {
				@include three-backgrounds-transition(defendant-3, 
				'backgrounds/defendant-prosecutor.jpg', 'backgrounds/defendant-defendant.jpg', 'backgrounds/defendant-defendant.jpg',
				1.37, 1.37, 1.37,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: defendant-3 2s ease 1 forwards;
				animation: defendant-3 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant.png')
				}
			}
			

			&-defendant-3 {
				@include three-backgrounds-transition(defendant-4, 
				'backgrounds/defendant-defendant.jpg', 'backgrounds/defendant-prosecutor.jpg', 'backgrounds/defendant-prosecutor.jpg',
				1.37, 1.37, 1.37,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: defendant-4 2s ease 1 forwards;
				animation: defendant-4 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant.png')
				}
			}


			&-defendant-5 {
				@include three-backgrounds-transition(defendant-5, 
				'backgrounds/defendant-2.jpg', 'backgrounds/defendant-2.jpg', 'backgrounds/defendant-legal-team-defendant.jpg',
				1.37, 1.37, 1.37,
				0, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: defendant-5 2s ease 1 forwards;
				animation: defendant-5 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant.png')
				}
			}

			
			&-defendant-6,
			&-defendant-8 {
				@include three-backgrounds-transition(defendant-6, 
				'backgrounds/defendant-legal-team-defendant.jpg', 'backgrounds/defendant-defendant.jpg', 'backgrounds/defendant-defendant.jpg',
				1.37, 1.37, 1.37,
				-37%, 0, -37%, 0, -37%, 0,
				);
				-webkit-animation: defendant-6 2s ease 1 forwards;
				animation: defendant-6 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant.png')
				}

			}
			&-defendant-7 {
				@include translateAndScale(-37%, 0 , 1.37);
				background-image: url('/assets/images/backgrounds/clock/defendant.png'),url('/assets/images/backgrounds/paper/defendant.png'),url('/assets/images/backgrounds/defendant-2.jpg');
			}

			&-defendant2-1 {
				@include three-backgrounds-transition(defendant2-1, 
				'backgrounds/legal-team-defendant2-legal-team-defendant.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg',
				1.37, 1.6, 1.6,
				-37%, 0, -60%, -13%, -60%, -13%,
				);
				-webkit-animation: defendant2-1 5s ease 1 forwards;
				animation: defendant2-1 5s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant2.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant2.png')
				}
			}
			&-defendant2-2 {

				@include three-backgrounds-transition(defendant2-2, 
				'backgrounds/welcome-3.jpg', 'backgrounds/defendant2-defendant.jpg', 'backgrounds/defendant2-defendant.jpg',
				1.6, 1.6, 1.6,
				-60%, -13%, -60%, -13%, -60%, -13%,
				);
				-webkit-animation: defendant2-2 2s ease 1 forwards;
				animation: defendant2-2 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant2.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant2.png')
				}
			}
			&-defendant2-3 {
				@include three-backgrounds-transition(defendant2-3, 
				'backgrounds/defendant2-defendant.jpg', 'backgrounds/welcome-3.jpg', 'backgrounds/welcome-3.jpg',
				1.6, 1.6, 1.6,
				-60%, -13%, -60%, -13%, -60%, -13%,
				);
				-webkit-animation: defendant2-3 2s ease 1 forwards;
				animation: defendant2-3 2s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/defendant2.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/defendant2.png')
				}
			}
			&-sentencing-1 {
				@include three-backgrounds-transition(sentencing, 
				'backgrounds/sentencing-1.jpg', 'backgrounds/welcome-1.jpg', 'backgrounds/sentencing-2.jpg',
				1, 1, 1,
				0, 0, 0, 0, 0, 0,
				);
				-webkit-animation: sentencing 5s ease 1 forwards;
				animation: sentencing 5s ease 1 forwards;
				.CourtRoom-background-paper {
					background-image: url('/assets/images/backgrounds/paper/sentencing.png');	
				}
				.CourtRoom-background-clock {
					background-image: url('/assets/images/backgrounds/clock/sentencing.png')
				}
			}
			&-sentencing-2 {
				@include translateAndScale(0, 0 , 1);
				background-image: url('/assets/images/backgrounds/clock/sentencing.png'),url('/assets/images/backgrounds/paper/sentencing.png'),url('/assets/images/backgrounds/sentencing-2.jpg');
			}
			
		}
		.CourtRoom-names {
		position: absolute;
		width: 36.32142857em;
		height: 0.9521428em;
		top: 25.42857em;
		left: 3.49285em;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		span {
			font-size: 0.642857em;
			font-weight: 800;
		}
	}
	}

	.Popup-container{
		&.animation-welcome{
			@include opacity(0);
			@include display-animation(welcomeDisplay);
			-webkit-animation: welcomeDisplay 1s 3s ease 1 forwards;
			animation: welcomeDisplay 1s 3s ease 1 forwards;
		}
	}
	.Popup-container {
		&.animation-legalTeamProsecutor,
		&.animation-legalTeamProsecutor2,
		&.animation-legalTeamDefendant,
		&.animation-personal,
		&.animation-defendant-1,
		&.animation-defendant2,
		&.animation-witnesses-1 {
			@include opacity(0);
			@include display-animation(popupFadein);
			-webkit-animation: popupFadein 1s 2s ease 1 forwards;
			animation: popupFadein 1s 2s ease 1 forwards;
		}
		
		
		&.animation-documents-1 {
			@include opacity(0);
			@include display-animation(popupFadein4);
			-webkit-animation: popupFadein4 1s 1.5s ease 1 forwards;
			animation: popupFadein4 1s 1.5s ease 1 forwards;
		}
		
		&.animation-sentencing-1,
		&.animation-witnesses-3 {
			@include opacity(0);
			@include display-animation(popupFadein2);
			-webkit-animation: popupFadein2 1s 4.5s ease 1 forwards;
			animation: popupFadein2 1s 4.5s ease 1 forwards;
		}
		&.animation-documents-4,
		&.animation-personal {
			@include opacity(0);
			@include display-animation(popupFadein5);
			-webkit-animation: popupFadein5 1s 1s ease 1 forwards;
			animation: popupFadein5 1s 1s ease 1 forwards;
		}
		&.animation-defendant-1{
			@include opacity(0);
			@include display-animation(popupFadein3);
			-webkit-animation: popupFadein3 1s 3s ease 1 forwards;
			animation: popupFadein3 1s 3s ease 1 forwards;
		}
		&.animation-judgeAsks {
			@include opacity(0);
			@include display-animation(judgeDisplay);
			-webkit-animation: judgeDisplay 1s 2s ease 1 forwards;
			animation: judgeDisplay 1s 2s ease 1 forwards;
		}
	}
	
	.CourtRoom-voting-talkToJudge,
	.CourtRoom-voting-talkToLayJudge {
		@include display-animation(displayDelay);
		&.static-voting {
			-webkit-animation: displayDelay 0s ease 1 forwards;
			animation: displayDelay 0s ease 1 forwards;
		}
		&.animation-voting {
			-webkit-animation: displayDelay 0s ease 1 forwards;
			animation: displayDelay 0s ease 1 forwards;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1.7664em;
		text-align: center;
		border-radius: 1.7857em;
		position: absolute;
		top: 19.507em;
		background-color: white;
		
		cursor: pointer;
		span {
			font-family: 'Roboto';
			font-weight: bold;
			font-size: 0.857em;
			z-index: 2;
		}
	}
	.CourtRoom-tip {
		padding: 0.942857em 1.17em 0.942857em 1.21em;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		color: black;
		height: 1.7664em;
		text-align: center;
		border-radius: 1.7857em;
		position: absolute;
		top: 22em;
		left: 9em;
		span {
			font-family: 'Roboto';
			font-weight: bold;
			font-size: 0.857em;
			z-index: 2;
		}
	}

}

   
  