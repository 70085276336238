@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	height: 100%;
	overflow: hidden;
	min-height: 100%;
	background-color: #111B34;
	@include flex($justify:"center", $align:"flex-start", $direction:"");
	.App-wrap {
		position: relative;
		width: 100%;
		height: 26.7857em;
		@include flex($justify:"center", $align:"flex-start", $direction:"");
	}
}

@media (min-aspect-ratio: 16/9) {
	.App .App-wrap {
		font-size: calc(3.74vh); // 14 px at 375 height
		width: 47.642em;
		height: 100%;
	}
}