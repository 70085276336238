@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.orientation {
	& img{
		position: absolute; 
		left: 0; 
		right: 0; 
		margin-left: auto; 
		margin-right: auto; 
		top: 8em;
		width: 10.714em;
		z-index: 2;
		height: auto;
	}
	& span{
		position: absolute; 
		left: 0; 
		right: 0; 
		margin-left: auto; 
		margin-right: auto; 
		width: 27.714em;
		top: 23.3857em;
		font-size: 1.2em;
	}
	
}