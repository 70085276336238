@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.PreCourt {
	position: relative;
	width: 100%;
	height: 26.7857em;
	overflow: hidden;


	background-image: url('/assets/images/backgrounds/voting-2.jpg');
	background-position: top left -0.1em;
	background-size: 101% 101%;
	@include origin(0, 0);
	-webkit-animation: fadeIn 1s ease-in 1;
	animation: fadeIn 1s ease-in 1;

	.PreCourt-body {
		@include no-select();
		position: absolute;
		top: 2.214em;
		left: 15em;
		z-index: 1;
		width: 18.25em;
		border-radius: 1.14em;
		color:#0A1E46;
		background-color: white;
		padding: 0.75em;
	
		
		height: 19.5em;
		.Carousel-indicators{position: absolute;
		top: 95%;}
		
			
		.PreCourt-textbox {
			width: 100%;
			height: 100%;
	
			.PreCourt-text {
				width: 100%;
				height: 100%;
				p {
					display: inline-block;
					white-space: normal;
					width: 100%;
					height: 100%;
					text-align: start;
					margin-top: 0.5em;
					strong {
						margin: 0;
						font-weight: 900;
						font-size: 1em;
						color: #0A1E46;
					}
				}
			}
			.PreCourt-answer {
				margin-top: -1em;
			}
			.PreCourt-options {
				margin-top: -1.5em;
				display: flex;
				justify-content: space-around;
				.PreCourt-option1,
				.PreCourt-option2 {
						&.blink {
							-webkit-animation: indication 1s infinite;
							animation: indication 1s infinite;
						}
					margin-top: auto;
					background-color: white;
					border: 0.0714em solid #0A1F45;
					width: 3.7857em;
					height: 1.5714em;					
					color:  #0A1F45;
					border-radius: 1.75em;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
						span {
							font-weight: 900;
						}
				}
			}
		}
		.PreCourt-texts p {
			margin: 0;
			font-weight: 400;
			font-size: 1em;
			color: #475674;
		}
		
		
	}
	
	.PreCourt-audioContainer {
		position: absolute;
		top: 23.285714em;
		left: 40.42857em;
	}
}
