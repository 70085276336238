@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieConsent {
	position: absolute;
	@include flex('center', 'center');
	width: 100%;
	height: 100%;

	.CookieConsent-consentWrapper {
		@include flex('', 'center', 'column');
		position: absolute;
		bottom: 0.571em;
		left: 0.571em;
		width: 1.428em;
		height: 1.428em;
		.CookieConsent-consentToggleContainer:hover {
			cursor: pointer;
		}
		.CookieConsent-consentToggleContainer {
			@include flex('', 'center', '');
			z-index: 50;
			position: relative;
			width: 100%;
			height: 100%;
			background-image: url('../../assets/images/cookie/cookies.svg');
			background-size: cover;
			
		}
		&.show {
			width: 2.331em;
			height: 2.331em;
			left: 1.021em;
			bottom: 1.128em;
		}
		
	}
	
	.CookieConsent-popup {
		z-index: 50;
		display: flex;
		position: absolute;
		width: 100%;
		height: 0;
		background: #CCD1CC;
		bottom:0;
		flex-direction: row;
		transition: height 0.50s ease-out;
		&.show {
			height: 5em;
			transition: height 0.50s ease-out;
			&.big {
				height: 13.92em;
				.CookieConsent-buttonWrapper {
					margin-top: 8.5em;
				}
			}
		}
		.CookieConsent-textWrapper {
			position: relative;
			margin-top: 0.57em;
			margin-left: 4.29em;
			width: 32.357em;
			
			.CookieConsent-textBody {
				span {
					font-size: 0.714em;
					color: black;
					font-family: 'Roboto', sans-serif;
					p {
						margin-block-start: 0em;
						margin-block-end: 0em;
					}
				}
				.CookieConsent-readMore {
					position: absolute;
					left: 17.9em;
					top: 2.4em;
					div {
						text-decoration: underline;
					}
				}
			}
		}

		.CookieConsent-buttonWrapper {
			width: 6.98em;
			height: 3.84em;
			margin-left: 1.92em;
			position: relative;
			margin-top: 0.571em;
			@include flex('space-between', 'space-between', 'column');
			.CookieConsent-button:hover {
				cursor: pointer;
			}
			.CookieConsent-button {
				@include flex('center', 'center', '');
				width: 100%;
				height: 1.766em;
				border-radius: 1em;
				.CookieConsent-buttonText {
					color: white;
					padding: 0.25em 0;
					font-size: 1em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
			.Ok {
				background-color: #0B9536;
			}
			.Cancel {
				background-color: #E1232D;
			}
		}
	}
}
