@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.LegalFramework {
	@include display-animation(displayDelay);

	@include opacity(0);
	@include doubleTransition('opacity', 0.33s, 'ease-in-out', 'visibility', 0.33s, 'ease-in-out');

	&.static-voting2 {
		-webkit-animation: displayDelay 0s ease 1 forwards;
		animation: displayDelay 0s ease 1 forwards;
	}
	&.animation-voting2 {
		-webkit-animation: displayDelay 5s ease 1 forwards;
		animation: displayDelay 5s ease 1 forwards;
	}
	padding: 0.942857em 1.17em 0.942857em 1.21em;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	color: black;
	text-align: center;
	border-radius: 1.7857em;
	width: 23.1428em;
	height: 13.5714em;
	position: absolute;
	top: 5.85714em;
	left: 8em;
	.LegalFramework-body {
		width: 100%;
		height: 100%;
		.LegalFramework-texts {
			width: 100%;
			height: 100%;
			.LegalFramework-text {
				width: 100%;
				height: 100%;
				strong {
					margin: 0;
					font-weight: 900;
					font-size: 1em;
					color: #0A1E46;
				}
				p {
					display: inline-block;
					white-space: normal;
					width: 100%;
					height: 100%;
					text-align: start;
					
				}
			}
		}
		.LegalFramework-texts p {
			margin: 0;
			font-weight: 400;
			font-size: 1em;
			color: #475674;
			
		}
	}
	.Carousel-indicators {margin-top: 1em;}
}