@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Outro {
	-webkit-animation: fadeIn 1s ease-in 1;
	animation: fadeIn 2s ease-in 1;

	position: relative;
	width: 100%;
	height: 26.7857em;
	background-image: url('/assets/images/backgrounds/depature.jpg');
	background-size: contain;
	background-position: top left;
	.Outro-content {
		margin-left: -2.5em;
		position: relative;
	}
	.Outro-textbox {
		position: absolute;
		color:#475674;
		width: 35.7857em;
		height: 14.85714em;
		border-radius: 1.14em;
		left: 7.214em;
		background-color: white;
		top: 3em;
		overflow: hidden;
		padding-left: 1em;
	}
	.Outro-line {
		position: absolute;
		top: 1.62142em;
		left: 7.5em;
		width: 15.865714em;
		height: 0.5em;
		background-color: $idle-color;
	}
	.Outro-optionOthers {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 2.357857em;
		height: 2.357857em;
		transform: translate(-0.5819285em, -0.5819285em);
		border: 0.14285714em solid white;
		border-radius: 100%;
		background-color: $idle-color;
		color: white;
		top: 1.37142em;
		&.current {
			background-color: #C6103B;
		}

		&.real {
			background-color: #FF8264;
		}
		&.option-1 {
			left: 7.5em;
		}
		&.option-2 {
			left: 11.271428em;
		}
		&.option-3 {
			left: 15.27857em;
		}
		&.option-4 {
			left: 19.321428em;
		}
		&.option-5 {
			left: 23.7071428em;
		}
		&.option-6 {
			left: 27.6142857em;
		}
		span {
			font-weight: 900;
			font-size: 0.785714em;
		}
	}
	.Outro-optionText {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 8.60857em;
		height: 7.31357em;
		color: white;
		top: 2.62857em;
		&.option-1 {
			left: 0.84285em;
		}
		&.option-2 {
			left: 4.957142em;
		}
		&.option-3 {
			left: 9.02142em;
		}
		&.option-4 {
			left: 13.142857em;
		}
		&.option-5 {
			left: 17.2571428em;
		}
		&.option-6 {
			left: 21.321428em;
		}
		span {
			font-size: 0.85714em;
			height: 1.28em;
			width: 10.04em;
			transform: rotate(-39deg);
			text-align: center;
			color: #0A1E46;
			font-weight: 900;
		}
	}
	.Outro-labels{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		width: 15.1935714em;
		left: 8.6357em;
		top: 12.71428em;
		
		.Outro-labelsYou{
			
			width:  0.715em;
			height: 0.715em;
			border-radius: 100%;
			&.real {
				background-color: #FF8264;
			}
			&.current {
				background-color: #C6103B;
			}
		}
		.Outro-labelsOthers{
			width:  0.715em;
			height: 0.715em;
			background-color: #0A1E46;
			border-radius: 100%;
		}
		.Outro-labelsReal{
			background-color: #FF8264;
			width:  0.715em;
			height: 0.715em;
			border-radius: 100%;
		}
		span {
			font-weight: 900;
			font-size: 0.85714em;
			color: #0A1E46;
			margin-right: 1.641em;
		}
	}
	.Outro-text {
		position: absolute;
		width: 10em;
		left: 26.714em;
		top: 1.4em;
		span {
			color: #0A1E46;
			font-weight: 900;
		}
	}
}